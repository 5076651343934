import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TestData } from '../testapi/testapi.component';
import { SurveyDataRequestBody } from '../models/surveyData.model';
import { GenericModel } from '../models/GenericModel.model';
import { CustomDataModel } from '../models/CustomDataModel';
import { getAPIURL } from 'src/app/util/config';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

const API_URL = getAPIURL();

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  constructor(private http: HttpClient) { }
  private async request(method: string, url: string, data?: any) {
    // console.log(url);

    const result = this.http.request<GenericModel[]>(method, url, {
      body: data,
      responseType: 'json',
      observe: 'body'
    });
    return new Promise<GenericModel[]>((resolve, reject) => {
      result.subscribe(resolve, reject);
    });
  }

  private async requestStrings(method: string, url: string, data?: any) {
    // console.log(url);

    const result = this.http.request<string[]>(method, url, {
      body: data,
      responseType: 'json',
      observe: 'body'
    });
    return new Promise<string[]>((resolve, reject) => {
      result.subscribe(resolve, reject);
    });
  }

  url_prefix = API_URL + `/api`;

  getUserIp() {
    let url = this.url_prefix + '/userIp';
    return this.request('GET', url);
  }

  //This method is to get latest household survey year
  getSurveyYears() {
    let url = this.url_prefix + '/surveyYears';
    return this.requestStrings('GET', url);
  }

  //This method is only for testing the API call
  getTestCount() {
    let url = this.url_prefix + '/test';
    return this.request('GET', url);
    }
    



  getStates() {
    let url = this.url_prefix + '/states';
    return this.request('GET', url);
  }

  getMsas() {
    let url = this.url_prefix + '/msas';
    return this.request('GET', url);
  }

  getRegions() {
    let url = this.url_prefix + '/regions';
    return this.request('GET', url);
  }

  private async CustomDatarequest(method: string, url: string, data?: any) {

    const result = this.http.request<CustomDataModel[]>(method, url, {
      body: data,
      responseType: 'json',
      observe: 'body'
    });
    return new Promise<CustomDataModel[]>((resolve, reject) => {
      result.subscribe(resolve, reject);
    });
  }
  getTopics(year: string, basiclist: string = null) {
    let url = "";
    if (basiclist == null) {
      url = this.url_prefix + '/topics?year=' + year;
    }
    else {
      url = this.url_prefix + '/topics?year=' + year + "&basiclist=" + basiclist;

    }
    return this.CustomDatarequest('GET', url, { year, basiclist });
  }
  getTopicsNew(year: string, basiclist: string = null) {
    let url = "";
    if (basiclist == null) {
      url = this.url_prefix + '/ntopics?year=' + year;
    }
    else {
      url = this.url_prefix + '/ntopics?year=' + year + "&basiclist=" + basiclist;

    }
    console.log(url);
    return this.CustomDatarequest('GET', url, { year, basiclist });
  }
  getCustomData() {
    let url = this.url_prefix + '/customData';
    return this.CustomDatarequest('GET', url);
  }
  getDataForSubTitle() {
    let url = this.url_prefix + '/subtitleData';
    return this.CustomDatarequest('GET', url);
  }
  getFilterData() {
    let url = this.url_prefix + '/customDataFilter';
    return this.CustomDatarequest('GET', url);
  }
  getCustomDataDistinct() {
    let url = this.url_prefix + '/customDataDistinct';
    return this.CustomDatarequest('GET', url);
  }
  getCustomDataColumnHeaders(variablename: string) {
    let url = this.url_prefix + '/ColumnHeaders?VariableName=' + variablename;
    return this.request('GET', url);
  }
  getStateNames() {
    let url = this.url_prefix + '/statenames';
    console.log(url);
    return this.request('GET', url);
  }
  getFiveYearMsas() {
    let url = this.url_prefix + '/fiveyearmsas';
    return this.request('GET', url);
  }
  getCustomTableData(postData: SurveyDataRequestBody) {
    console.log('getSurveyData called with postData...');
    // console.log(postData);
    let url = this.url_prefix + '/customDataTable';
    return this.request('POST', url, postData);
  };

  getSurveyData(postData: SurveyDataRequestBody) {
    console.log('getSurveyData called with postData...');
    // console.log(postData);
    let url = this.url_prefix + '/data';
    return this.request('POST', url, postData);
  };

  getXTopics(year: string) {
    let url = this.url_prefix + '/xtopics?year=' + year;
    return this.request('GET', url, year);
  }

  getYTopics(year: string) {
    let url = this.url_prefix + '/ytopics?year=' + year;
    return this.request('GET', url, year);
  }

  getChartFilterTopics(year: string, nation: string) {
    let url = this.url_prefix + '/chartfiltertopics?year=' + year + "&nationflag=" + nation;
    return this.CustomDatarequest('GET', url, { year, nation });
  }
}